exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amplified-by-technology-jsx": () => import("./../../../src/pages/amplified-by-technology.jsx" /* webpackChunkName: "component---src-pages-amplified-by-technology-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-consumer-rights-jsx": () => import("./../../../src/pages/consumer-rights.jsx" /* webpackChunkName: "component---src-pages-consumer-rights-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-contact-us-success-jsx": () => import("./../../../src/pages/contact-us-success.jsx" /* webpackChunkName: "component---src-pages-contact-us-success-jsx" */),
  "component---src-pages-download-jsx": () => import("./../../../src/pages/download.jsx" /* webpackChunkName: "component---src-pages-download-jsx" */),
  "component---src-pages-for-consumers-jsx": () => import("./../../../src/pages/for-consumers.jsx" /* webpackChunkName: "component---src-pages-for-consumers-jsx" */),
  "component---src-pages-for-payers-jsx": () => import("./../../../src/pages/for-payers.jsx" /* webpackChunkName: "component---src-pages-for-payers-jsx" */),
  "component---src-pages-for-providers-jsx": () => import("./../../../src/pages/for-providers.jsx" /* webpackChunkName: "component---src-pages-for-providers-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-investors-jsx": () => import("./../../../src/pages/investors.jsx" /* webpackChunkName: "component---src-pages-investors-jsx" */),
  "component---src-pages-learn-more-jsx": () => import("./../../../src/pages/learn-more.jsx" /* webpackChunkName: "component---src-pages-learn-more-jsx" */),
  "component---src-pages-mindoula-in-the-news-jsx": () => import("./../../../src/pages/mindoula-in-the-news.jsx" /* webpackChunkName: "component---src-pages-mindoula-in-the-news-jsx" */),
  "component---src-pages-news-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/news/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-news-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-our-company-jsx": () => import("./../../../src/pages/our-company.jsx" /* webpackChunkName: "component---src-pages-our-company-jsx" */),
  "component---src-pages-our-mindoulas-jsx": () => import("./../../../src/pages/our-mindoulas.jsx" /* webpackChunkName: "component---src-pages-our-mindoulas-jsx" */),
  "component---src-pages-our-team-jsx": () => import("./../../../src/pages/our-team.jsx" /* webpackChunkName: "component---src-pages-our-team-jsx" */),
  "component---src-pages-press-releases-jsx": () => import("./../../../src/pages/press-releases.jsx" /* webpackChunkName: "component---src-pages-press-releases-jsx" */),
  "component---src-pages-privacy-concerns-jsx": () => import("./../../../src/pages/privacy-concerns.jsx" /* webpackChunkName: "component---src-pages-privacy-concerns-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-refer-someone-jsx": () => import("./../../../src/pages/refer-someone.jsx" /* webpackChunkName: "component---src-pages-refer-someone-jsx" */),
  "component---src-pages-refer-someone-success-jsx": () => import("./../../../src/pages/refer-someone-success.jsx" /* webpackChunkName: "component---src-pages-refer-someone-success-jsx" */),
  "component---src-pages-rooted-in-human-connection-jsx": () => import("./../../../src/pages/rooted-in-human-connection.jsx" /* webpackChunkName: "component---src-pages-rooted-in-human-connection-jsx" */),
  "component---src-pages-service-principles-jsx": () => import("./../../../src/pages/service-principles.jsx" /* webpackChunkName: "component---src-pages-service-principles-jsx" */),
  "component---src-pages-setting-a-new-standard-jsx": () => import("./../../../src/pages/setting-a-new-standard.jsx" /* webpackChunkName: "component---src-pages-setting-a-new-standard-jsx" */),
  "component---src-pages-sms-terms-jsx": () => import("./../../../src/pages/sms-terms.jsx" /* webpackChunkName: "component---src-pages-sms-terms-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

