import React from 'react';
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {SnackbarProvider} from "notistack";
import { AnimatePresence, motion } from 'framer-motion';
 
import "./src/stylesheets/styles.scss";
import MuiThemeProvider from "./src/components/MuiThemeProvider";

export const wrapPageElement = ({element}) => (
  <LocalizationProvider dateAdapter={AdapterLuxon}>
    <MuiThemeProvider>
      <SnackbarProvider maxSnack={8}>
        <AnimatePresence>
          <motion.div exit={{ opacity: 0 }} transition={{ when: "beforeChildren" }} style={{ height: '100%' }}>
            {element}
          </motion.div>
        </AnimatePresence>
      </SnackbarProvider>
    </MuiThemeProvider>
  </LocalizationProvider>
);
